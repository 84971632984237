import React, { useEffect } from "react"

const CookiePage = () => {
  const lang = (process.env.GATSBY_LANG || "it_IT").split("_")[0]

  useEffect(() => {
    window.location.replace(`/${lang}/cookie-policy`)
  }, [])

  return null
}

export default CookiePage
